<template>
  <div class="mini-clock-container">
    <b-img
      v-if="data.image"
      class="clock-img"
      :src="data.image"
      alt="Mini Clock"
    ></b-img>
    <span class="clock-text">{{ data.text }}</span>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>
<style scoped lang="scss">
.mini-clock-container {
  display: flex;
  align-items: center;
  .clock-img {
    margin-right: 15px;
    width: 1.5vw;
    max-width: 60px;
    filter: brightness(0) saturate(10%) invert(26%) sepia(10%) saturate(7900%)
      hue-rotate(190deg) brightness(80%);
    margin-top: 5px;
  }
  .clock-text {
    font-size: clamp(5px, 1.8vw, 70px);
    font-family: Montserrat-Regular;
    letter-spacing: 1px;
  }
}
</style>
